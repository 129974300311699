<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <el-date-picker
          v-model="params.selectTime"
          style="width: 260px"
          type="daterange"
          align="right"
          unlink-panels
          :clearable="false"
          range-separator="-"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="yesterdayOptions"
        >
        </el-date-picker>
        <el-select
          v-model="params.adminUserId"
          filterable
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="用户"
        >
          <el-option
            v-for="item in filUser"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset"> 重置 </el-button>
      </div>
    </div>
    <!-- 表格数据 -->
    <el-table
      class="assess-value"
      :data="dataList"
      style="width: 100%"
      :border="true"
      height="690px"
      :header-cell-class-name="handleHeaderCell"
      @sort-change="handleSortChange"
    >
      <el-table-column
        prop="adminUserName"
        align="center"
        header-align="center"
        label="姓名"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop="selectDate"
        align="right"
        header-align="center"
        label="日期"
        min-width="100"
      >
      </el-table-column>

      <!-- 充值详情 -->
      <el-table-column label="充值" align="center">
        <el-table-column label="全量" align="center">
          <el-table-column
            label="充值"
            prop="totalRechargeAmt"
            align="center"
          ></el-table-column>
          <el-table-column
            label="增长(%)"
            prop="totalRechargeAmtMounting"
            align="center"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="老用户" align="center">
          <el-table-column
            label="充值"
            prop="oldRechargeAmt"
            align="center"
          ></el-table-column>
          <el-table-column
            label="增长(%)"
            prop="oldRechargeAmtMounting"
            align="center"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="新用户" align="center">
          <el-table-column
            label="充值"
            prop="newRechargeAmt"
            align="center"
          ></el-table-column>
          <el-table-column
            label="增长(%)"
            align="center"
            prop="newRechargeAmtMounting"
          ></el-table-column>
        </el-table-column>
      </el-table-column>
      <!-- 单粉充值 -->
      <el-table-column label="单粉产值" align="center">
        <el-table-column
          prop="totalFansPrice"
          label="全量"
          align="right"
          header-align="center"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="oldUserFansPrice"
          label="老用户"
          header-align="center"
          align="right"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="newUserFansPrice"
          label="新用户"
          header-align="center"
          align="right"
          min-width="80"
        >
        </el-table-column>
      </el-table-column>
      <!-- 留存率 -->
      <el-table-column label="留存率(%)" align="center">
        <el-table-column
          prop="totalKeepRate"
          label="全量"
          header-align="center"
          align="right"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="oldUserKeepRate"
          label="老用户"
          align="right"
          header-align="center"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="newUserKeepRate"
          label="新用户"
          align="right"
          header-align="center"
          min-width="80"
        >
        </el-table-column>
      </el-table-column>
      <!-- 付费率 -->
      <el-table-column label="付费率(%)" align="center">
        <el-table-column
          prop="totalPayRate"
          label="全量"
          align="right"
          header-align="center"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="oldUserPayRate"
          label="老用户"
          align="right"
          header-align="center"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="newUserPayRate"
          label="新用户"
          align="right"
          header-align="center"
          min-width="80"
        >
        </el-table-column>
      </el-table-column>
      <!-- 复充率 -->
      <el-table-column label="复充率(%)" align="center">
        <el-table-column
          prop="totalRepeatRechargeRate"
          label="全量"
          align="right"
          header-align="center"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="oldRepeatRechargeRate"
          label="老用户"
          align="right"
          header-align="center"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="newUserRepeatRechargeRate"
          label="新用户"
          align="right"
          header-align="center"
          min-width="80"
        >
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="liveUserRate"
        align="right"
        header-align="center"
        label="活跃率(%)"
        min-width="100"
      >
      </el-table-column>
    </el-table>
    <!-- 公共抽屉 -->
    <common-drawer
      :showDrawer="showCommonDrawer"
      size="90%"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />
  </div>
</template>

<script>
import {
  platformList,
  yesterdayOptions,
  labelList,
  pickerOptions,
} from "@/assets/js/options";
import { formatThousandsDots, getRecentlyWeek } from "@/assets/js/utils";
import { getBookList } from "@/api/account";
import { getOperationAssess, getFields } from "@/api/statics";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "intervalChannel",
  props: {
    lastParams: Object,
  },
  computed: {
    ...mapGetters(["userList", "officialList"]),
    filUser: function () {
      const tmpUser = this.$store.state.userList.filter((item) => {
        return item.isPut === 0;
      });
      return tmpUser;
    },
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
  },
  watch: {
    lastParams: function () {
      this.getData("drawer");
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        // 报错处理
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
        // return formatThousandsDots(value.toString());
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      sortDesc: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      pickerOptions,
      platformList,
    };
  },
  methods: {
    // 头部样式回调
    handleHeaderCell({ rowIndex, columnIndex }) {
      // const seccolumn = [0, 1, 2, 6, 7, 8]
      if (
        (columnIndex === 2 || columnIndex === 4 || columnIndex === 6) &&
        rowIndex === 0
      ) {
        return "color-red";
      }
      // if (rowIndex === 1 && seccolumn.includes(columnIndex)) {
      //   return "color-red";
      // }
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.params.selectTime = getRecentlyWeek(true);
      this.params.originPlatform = null;
      this.params.accountChannelId = null;
      this.params.adminUserId = null;
      this.params.ruledOutAccountChannelIds = [];
      this.sortName = null;
      this.sortDesc = null;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
      this.$nextTick(() => {
        this.$refs.userConsumeTable.doLayout();
      });
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      const tody = getRecentlyWeek(true);
      if (!this.params.selectTime) {
        this.$set(this.params, "selectTime", tody);
      }
      this.loading = true;
      // let tmpFields = null;
      // if (this.sendField.length > 0) {
      //   tmpFields = this.handleFields(this.sendField);
      // } else {
      //   this.showField = this.originFeild;
      // }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      if (flag === "created") {
        tmpform = {
          beginDate: this.params.selectTime[0],
          endDate: this.params.selectTime[1],
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.accountChannelId,
          adminUserId: this.params.adminUserId,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds
              : null,
          // showFieldCodes: tmpFields,
        };
      } else if (flag === "drawer") {
        // if (this.lastParams.booksId) {
        //   this.getBookById(this.lastParams.booksId);
        // }
        tmpform = {
          ...this.lastParams,
        };

        this.params = { ...this.lastParams };
      } else {
        tmpform = {
          beginDate: this.params.selectTime[0],
          endDate: this.params.selectTime[1],
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.accountChannelId,
          adminUserId: this.params.adminUserId,
          ruledOutAccountChannelIds:
            this.params.ruledOutAccountChannelIds &&
            this.params.ruledOutAccountChannelIds.length
              ? this.params.ruledOutAccountChannelIds.join()
              : null,
          // showFieldCodes: tmpFields,
        };
      }
      getOperationAssess(tmpform)
        .then((res) => {
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          this.dataList = res;
          // 如果不是抽屉 缓存参数
          if (flag !== "drawer") {
            this.addRouterHistory({
              path: this.$route.path,
              params: {
                ...tmpform,
              },
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // 获取字段
    handleGetFeilds(flag = "created") {
      getFields({ dataType: "operationAssess" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData(flag);
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
  },
  created() {
    if (this.lastParams) {
      // this.handleGetFeilds("drawer");
      return false;
    }
    // this.getBook();
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      // this.showField = this.$store.state.routerHistory[
      //   tmpIndex
      // ].params.cacheShowField;
      // // 获取缓存发送前格式字段
      // this.sendField = this.$store.state.routerHistory[
      //   tmpIndex
      // ].params.cacheSendField;
    }
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    // this.handleGetFeilds();
    this.getData("created");
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
// 表头
/deep/ .el-table--border th,
/deep/.el-table .has-gutter tr th {
  border-bottom: 1px solid rgb(235, 238, 245) !important;
  border-right: 1px solid rgb(235, 238, 245) !important;
}
.assess-value /deep/ .thead th {
  padding: 5px 0 !important;
}
/deep/ .el-table--small th {
  padding: 0 !important;
}
/deep/ .color-red {
  background-color: #ddebf7 !important;
}
.el-table /deep/ tr td {
  font-size: 13px;
}
</style>
